//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormInputTitle from './form-input-title.vue';
import FormInput from './form-input.vue';

export default {
  components: {
    FormInputTitle,
    FormInput
  },
  props: {
    placeholder: String,
    invalid: [String, undefined],
    accept: {
      type: String,
      default: 'image/png, image/jpeg, application/pdf'
    }
  },
  data: () => ({
    file: null
  }),
  computed: {
    fileName() {
      if (this.file === null) {
        return '';
      }

      return this.file.name;
    }
  },
  methods: {
    triggerSelectFile() {
      this.$refs.input.click();
    },
    handleMainAction() {
      if (this.file === null) {
        this.triggerSelectFile();
        return;
      }

      this.file = null;
    },
    handleInputFileChange(event) {
      const [file] = event.target.files;
      this.file = file;
      this.$emit('change', file);
      this.resetInputFile();
    },
    resetInputFile() {
      this.$refs.input.type = 'text';
      this.$nextTick(() => {
        this.$refs.input.type = 'file';
      });
    }
  }
};
